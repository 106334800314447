import React, {Component} from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import IndexPage from './pages/IndexPage/IndexPage'
import QuizPage from './pages/QuizPage/QuizPage'
import CongratulationPage from './pages/CongratulationPage/CongratulationPage'



class App extends Component {
  render(){
    return (
      <Switch>
        <Route path="/" exact component={IndexPage} />
        <Route path="/quiz" component={QuizPage} />
        <Route path="/congratulation" component={CongratulationPage} />
        <Redirect to={{ pathname: "/" }} />
      </Switch>
    )
  }
}

export default App;
