import React, {Component} from 'react'
import classes from './IndexPage.module.scss'
import EffectAnimation from "../../Components/EffectAnimation/EffectAnimation";


class IndexPage extends Component{

  componentDidMount() {
    EffectAnimation('indexPage');
  }

  startTheQuiz = () => {
    window.location.assign('/quiz');
  }

  render(){
    return(
      <div className={classes.IndexPage} id="indexPage">
        <div className={classes.innerWrapper}>
          <h1 className={classes.title}>Start Quiz</h1>
          <div className={classes.btnWrap}>
            <button onClick={this.startTheQuiz}>
              <span>Go!</span>
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4559 7L1.35449 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.0645 13L15.4677 7L10.0645 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default IndexPage