import classes from './AnswersBlock.module.scss'
import AnswerItem from './AnswerItem/AnswerItem'


const AnswersBlock = (props) => {
  return (
    <div className={classes.AnswersBlock} id="AnswerBlock">
      { props.answers.map((answer, index) => {
         return(
           <AnswerItem
             key={index}
             answer={answer}
             onAnswerClick={props.onAnswerClick}
           />
         )
      }) }
    </div>
  )
}

export default AnswersBlock