import React, {Component} from 'react'
import QuestionBlock from '../../containers/QuestionBlock/QuestionBlock'
import EffectAnimation from "../../Components/EffectAnimation/EffectAnimation";
import classes from './QuizPage.module.scss'


class QuizPage extends Component {

  componentDidMount() {
    EffectAnimation('leftSide');
  }

  render() {
    return (
      <div className={classes.QuizPage}>
        <div className={classes.innerWrapper}>
          <div className={classes.leftSide} id="leftSide">
            <div className={classes.leftTitle}>
              Are you in the 1% of Top Social Media Influencers?
            </div>
          </div>
          <div className={classes.rightSide}>
            <QuestionBlock/>
          </div>
        </div>
      </div>
    )
  }

}

export default QuizPage