import classes from './AnswerItem.scss'
import check from './check.svg'


const AnswerItem = (props) =>{
  if(props.answer.type === 'btn' || props.answer.type === 'radio'){
    return(
      <div
        className="AnswerItem"
        onClick={(e) => props.onAnswerClick(e, 'activeAnswer', props.answer.type)}
      >
        { props.answer.text }
      </div>
    )
  }
  else if(props.answer.type === 'input'){
    return(
      <div
        className="AnswerItemInput"
      >
        <input type="text"
          placeholder={props.answer.text}
        />
      </div>
    )
  }




}

export default AnswerItem