import React, {Component} from 'react'
import classes from './CongratulationPage.module.scss'
import medal from './medal.svg'
import EffectAnimation from "../../Components/EffectAnimation/EffectAnimation";

class CongratulationPage extends Component{
  componentDidMount() {
    EffectAnimation('leftSide');
  }


  render() {
    return (
      <div className={classes.CongratulationPage}>
        <div className={classes.innerWrapper}>
          <div className={classes.leftSide} id="leftSide">
            <div className={classes.leftTitle}>
              Congratulations, Andrey!
            </div>
          </div>
          <div className={classes.rightSide}>
            <div className={classes.imgWrap}>
              <img src={medal} alt=""/>
            </div>
            <div className={classes.topText}>
              You are one of the <b>1%</b> of influencers who can successfully leverage all levels of monetization
            </div>
            <div className={classes.bottomText}>
              Use your knowladge and join a <b>new social network</b> where you can <b>earn tokens</b>(like bitcoin, ethereum etc..) by monetizing your entire existing audience
            </div>
            <div className={classes.btnWrap}>
              <button>
                <span>Get private invitation</span>
                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.4559 7L1.35449 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M10.0645 13L15.4677 7L10.0645 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CongratulationPage