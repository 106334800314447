import React, {Component} from 'react'
import classes from './QuestionBlock.module.scss'
import ActiveQuestion from '../../Components/ActiveQuestion/ActiveQuestion'


class QuestionBlock extends Component{
  state ={
    activeQuestion: 0,
    checkedAnswers: null,
    quiz: [
      {
        question: 'Please select the social media network(s) where you have account(s)',
        id: 1,
        answers:[
          {type: 'btn', text: 'YouTube'},
          {type: 'btn', text: 'Facebook'},
          {type: 'btn', text: 'Instagram'},
          {type: 'btn', text: 'Tiktok'},
          {type: 'btn', text: 'Twitter'},
          {type: 'btn', text: 'Snapchat'}
        ]
      },
      {
        question: 'What’s your accumulated social media audience size? ',
        id: 2,
        answers:[
          {type: 'btn', text: '50K - 100K'},
          {type: 'btn', text: '100K - 500K'},
          {type: 'btn', text: '500K - 1M'},
          {type: 'btn', text: '1M & above'}
        ]
      },
      {
        question: 'On a scale of 1 to 10, how skilled are you with analytics of your contents and data?',
        id: 3,
        answers:[
          {type: 'input', text: 'Enter your answer'},
        ]
      },
      {
        question: 'Do you have collaborations with other influencers in your industry?',
        id: 4,
        answers:[
          {type: 'radio', text: 'Yes'},
          {type: 'radio', text: 'No'},
        ]
      },
      {
        question: 'How many views/ likes you had on your most popular post?',
        id: 5,
        answers:[
          {type: 'input', text: 'Enter your answer'},
        ]
      },
      {
        question: 'How often do you post on your social media platforms?',
        id: 6,
        answers:[
          {type: 'btn', text: 'Once a day'},
          {type: 'btn', text: '2-3 times a day'},
          {type: 'btn', text: 'Once in few days'},
          {type: 'btn', text: 'Once in a week'},
          {type: 'btn', text: 'More than once a day'},
          {type: 'btn', text: 'More than once in a week'},
          {type: 'input', text: 'Enter your answer'}
        ]
      },
      {
        question: 'Have you ever been on tv or in a newspaper?',
        id: 7,
        answers:[
          {type: 'radio', text: 'Yes'},
          {type: 'radio', text: 'No'},
        ]
      },
      {
        question: 'How often do you use SEO to optimize your posts?',
        id: 8,
        answers:[
          {type: 'radio', text: 'All the time'},
          {type: 'radio', text: 'A couple of times'},
          {type: 'radio', text: 'Once in a while'},
          {type: 'radio', text: 'Never tried it'}
        ]
      },
      {
        question: 'On the average, how many subscribers/ followers do you get every month?',
        id: 9,
        answers:[
          {type: 'input', text: 'Enter your answer'},
        ]
      },
      {
        question: 'How often do you keep up-to date with digital marketing trends and news?',
        id: 10,
        answers:[
          {type: 'radio', text: 'Everyday'},
          {type: 'radio', text: 'Once in few days'},
          {type: 'radio', text: 'Few times a week'},
          {type: 'radio', text: 'Barely keep up'}
        ]
      },
      {
        question: 'How long have you been a social media influencer?',
        id: 11,
        answers:[
          {type: 'radio', text: 'Less than 6 months'},
          {type: 'radio', text: '6 months - 1 Year'},
          {type: 'radio', text: '1 Year - 5 Years'},
          {type: 'radio', text: 'More than 5 Years'}
        ]
      },
      {
        question: 'How often do you respond to your followers comments or questions?',
        id: 12,
        answers:[
          {type: 'radio', text: 'All the time'},
          {type: 'radio', text: 'A couple of times a day'},
          {type: 'radio', text: 'Once in a while'},
          {type: 'radio', text: 'Hardly keep up'}
        ]
      },
      {
        question: 'Do you have any fan pages/ communities?',
        id: 13,
        answers:[
          {type: 'radio', text: 'Yes'},
          {type: 'radio', text: 'No'},
        ]
      },
      {
        question: 'Have you ever analyzed your audience? (gender, age, country etc.)',
        id: 14,
        answers:[
          {type: 'radio', text: 'Yes'},
          {type: 'radio', text: 'No'},
        ]
      },
      {
        question: 'As an Influencer, do you have a business strategy on how to grow your influence?',
        id: 15,
        answers:[
          {type: 'radio', text: 'Yes'},
          {type: 'radio', text: 'No'},
        ]
      }
    ]
  }


  onAnswerClickHandler = (e, activeClass, type) => {
    if(type === 'btn'){
      let current = e.currentTarget;
      current.classList.toggle(activeClass);
    }
    else if(type === 'radio'){

      let answerBlock = document.querySelector('#AnswerBlock'),
          active = answerBlock.querySelector('.activeAnswer'),
          current = e.currentTarget;

      if(active){
        active.classList.remove('activeAnswer');
      }

      current.classList.toggle(activeClass);

    }

  }

  nextQuestionClickHandler = () => {
    if(!this.isQuizFinished()){
      let answerBlock = document.querySelector('#AnswerBlock'),
          activies = answerBlock.querySelectorAll('.activeAnswer');


      for(let i = 0; i < activies.length; i ++){
        activies[i].classList.remove('activeAnswer');
      }
      this.setState({
        activeQuestion: this.state.activeQuestion + 1
      })
    }
    else{
      window.location.assign('/congratulation');
    }
  }

  isQuizFinished = () => {
    return this.state.activeQuestion + 1 === this.state.quiz.length
  }

  render(){
    return(
      <div className={classes.QuestionBlock}>
        <ActiveQuestion
          answers={this.state.quiz[this.state.activeQuestion].answers}
          question={this.state.quiz[this.state.activeQuestion].question}
          onAnswerClick={this.onAnswerClickHandler}
          quizLength={this.state.quiz.length}
          answerNumber={this.state.activeQuestion + 1}
        />
        <div className={classes.btnWrap}>
          <button onClick={this.nextQuestionClickHandler}>
            <span>Next</span>
            <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4559 7L1.35449 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10.0645 13L15.4677 7L10.0645 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    )
  }
}


export default QuestionBlock