import React from "react";
import classes from './ActiveQuestion.module.scss'
import AnswersBlock from './AnswersBlock/AnswersBlock'


const ActiveQuestion = (props) =>{
  return(
    <>
      <div className={classes.preTitle}>
        Question {props.answerNumber}/{props.quizLength}
      </div>
        <div className={classes.questionTitle}>
          {props.question}
      </div>
      <AnswersBlock
        answers={props.answers}
        onAnswerClick={props.onAnswerClick}
      />
    </>
  )
}

export default ActiveQuestion